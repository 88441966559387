<template>
	<div class="OrderDetail">
		
		<div class="Top">
			<div class="Left">
				订单详情
			</div>
			<div class="Right">
				<span>
					<el-button type="primary" icon="el-icon-arrow-left" @click="$router.go(-1)">返回订单列表</el-button>
				</span>
			</div>
		</div>
		
		<div class="MainBody" v-if="Order.CreatedAt != undefined">
			
		<div class="BaseInfo">
			订单状态:{{Order.StatusName}},下单时间:{{Order.CreatedAt}}
		</div>
			
			
		<div class="Detail">
			<div class="List Title">
				<span class="C0"></span>
				<span class="C1">商品</span>
				<span class="C2">属性</span>
				<span class="C3">单价</span>
				<span class="C4">数量</span>
				<span class="C5">小计</span>
				<span class="C6" v-if="Order.Status >= 80">操作</span>
			</div>
			
			<div class="List" v-for="(child,childI) in Order.Children" :key="childI">
				<span class="C0">
					<img :src="child.GoodsThumb" />
				</span>
				<span class="C1">
					<a style="color: rgba(0,0,0,0.6);" :href="'http://www.lsysmall.com/#/shop/'+Order.ShopId" target="_blank">[{{Order.Shop.Name}}]</a>
					<a style="color: rgba(0,0,0,0.6);" :href="'http://www.lsysmall.com/#/goods/detail/'+child.GoodsId" target="_blank">{{child.GoodsName}}</a>
				</span>
				<span class="C2">{{child.SkuName}}</span>
				<span class="C3">{{child.Price}}</span>
				<span class="C4">{{child.Num}}</span>
				<span class="C5">{{child.TotalFee}}</span>
				<span class="C6" v-if="Order.Status >= 80 && Order.Status < 100">
					<el-button size="mini" @click="$Jump('/my/order/appeal_new/'+child.Id)" v-if="child.AppealId == ''">退换货</el-button>
					<el-button size="mini" type="danger" @click="$Jump('/my/order/appeal/'+child.AppealId)" v-else>退换中</el-button>
				</span>
			</div>
			
			<div class="Count">
				<li><span>商品总额：</span><em>¥{{Order.TotalFee}}</em></li>
				<li><span>运费：</span><em>¥{{Order.ExpressFee}}</em></li>
				<li><span>应付总额：</span><em>¥{{Order.TotalFee}}</em></li>
				
				<li style="margin-top: 10px;"  v-if="Order.Status == 60 && Order.ShopId == 'cfe6e340-2fe7-4d18-b2d0-775c72dcd78f'">
					<span>
						
					</span>
					<em>
						<el-button size="mini" type="danger" @click="SnOrderPay()">下发苏宁订单</el-button>
					</em>
				</li>
				
				<li style="margin-top: 10px;"  v-if="Order.Status >= 70 && Order.Status < 80">
					<span>
						
					</span>
					<em>
						<el-button size="medium" type="primary" @click="ExpressCheck()">确认收货</el-button>
					</em>
					<p style="padding: 0px;font-size: 0.8rem;color: rgba(0,0,0,0.3);margin: 0px;margin-top: 5px;">
						如有货物问题，可在确认收货后发起申诉
					</p>
				</li>
			</div>
			
		</div>
		
		<div class="Express">
			
			<div class="Address">
				<h3>收货人信息</h3>
				<li><span>收货人：</span>{{Order.Address.Addressee}}</li>
				<li><span>地址：</span>{{Order.Address.Province}}{{Order.Address.City}}{{Order.Address.Region}}{{Order.Address.Town ? Order.Address.Town:''}}{{Order.Address.Detail}}</li>
				<li><span>手机号码：</span>{{Order.Address.Mobile}}</li>
				
				<h3 class="Invoice">发票信息</h3>
				<template v-if="Order.Invoice">
					<template v-if="Order.Invoice.Type == 'special'">
						<li><span>发票类型：</span>增值税专票</li>
						<li><span>发票抬头：</span>{{Order.Invoice.Title}}</li>
						<li><span>税号：</span>{{Order.Invoice.DutyParagraph}}</li>
						<li><span>单位开户银行：</span>{{Order.Invoice.CompanyBank}}</li>
						<li><span>单位银行账号：</span>{{Order.Invoice.CompanyBankCardId}}</li>
						<li><span>单位联系地址：</span>{{Order.Invoice.CompanyAddress}}</li>
					</template>
					<template v-if="Order.Invoice.Type == 'normal'">
						<li><span>发票类型：</span>增值税普票</li>
						<li><span>发票抬头：</span>{{Order.Invoice.Title}}</li>
						<li><span>税号：</span>{{Order.Invoice.DutyParagraph}}</li>
						<li v-if="Order.Invoice.Mail"><span>电子发票邮箱：</span>{{Order.Invoice.Mail}}</li>
					</template>
					<template v-if="Order.Invoice.Type == 'person'">
						<li><span>发票类型：</span>增值税普票</li>
						<li><span>发票抬头：</span>{{Order.Invoice.Title}}</li>
						<li v-if="Order.Invoice.Mail"><span>电子发票邮箱：</span>{{Order.Invoice.Mail}}</li>
					</template>
				</template>
				<template v-else>
					<li>未设置发票，可联系客服补开</li>
				</template>
				
			</div>
			
			<div class="Dynamic">
				<h3>
					快递动态
					<span v-if="Order.Express">{{Order.Express.ExpressCompanyName}}<em>(单号:{{Order.Express.ExpressCode}})</em></span>
				</h3>
				
				<div class="Info">
				  <vuescroll :ops="vuescroll_ops" ref="vs">
				  	  <div v-if="Order.Express && Order.Express.Dynamic">
						  <el-steps :space="'40px'" direction="vertical" :active="1">
						    <el-step :title="d.ftime + ' ' + d.context" :key="dI" v-for="(d,dI) in Order.Express.Dynamic"></el-step>
						  </el-steps>
					  </div>
				    </vuescroll>
				</div>
				
			</div>
			
		</div>
		
		</div>
		
	</div>
</template>

<script>
	import {Select,Option,Dropdown,DropdownMenu,DropdownItem,Pagination,Steps,Step} from 'element-ui'
	import vuescroll from 'vuescroll'
	export default {
	  name: 'MyOrderDetail',
	  props: {
	  },
	  data() {
	      return {
			  Order:{
				  Id:''
			  },
			  OrderStepActive:1,
			  vuescroll_ops: {
			  	vuescroll: {},
			  	scrollPanel: {},
			  	rail: {
			  	},
			  	bar: {
			  		onlyShowBarOnScroll:false,
			  		background: '#000000',
			  		opacity: 0.2,
			  	}	
			  },
	      }
	  },
	  components: {
		'el-dropdown':Dropdown,
		'el-dropdown-menu':DropdownMenu,
		'el-dropdown-item':DropdownItem,
		'el-select':Select,
		'el-option':Option,
		'el-pagination':Pagination,
		'el-steps':Steps,
		'el-step':Step,
		'vuescroll':vuescroll
	  },
	  created() {
		this.Order = {
			Id:this.$route.params.Id
		}
		this.GetDetail()
	  },
	  methods:{
		  SnOrderPay(){
			  let data = {
					Service:'Order',
					Class: 'Order',
					Action: 'SnOrderPay',
					Id:this.Order.Id,
			  }
			  this.$post(this.$store.getters.getApiHost,data)
			  .then((res) => {
			  				
			  					this.$message(res.Msg)
			  					
			  				
			  })
			  .catch(function (response) {
			  		this.$message('网络请求快递信息错误')
			  })
		  },
		  ExpressCheck(){
			  this.$confirm('是否确认收货？收货后如有货物问题可发起申诉/退换')
				.then(() => {
				  this.OrderUpdate()
				})
		  },
		  OrderUpdate(){
			  let data = {
				Service:'Order',
				Class: 'Order',
				Action: 'Update',
				Id:this.Order.Id,
				Status:80,
			  }
			  this.$post(this.$store.getters.getApiHost,data)
			  .then((res) => {
			  				
					this.$message(res.Msg)
					
					if(res.ErrorId != 0){
						return
					}
					
					this.Order.Status = 80
					this.Order.StatusName = '已收货'
					this.OrderStepActive += 1
			  				
			  })
			  .catch(function (response) {
			  		this.$message('网络请求快递信息错误')
			  })
		  },
		  GetDetail(){
		  
		  		let data = {
		  			Service:'Order',
		  			Class: 'Order',
		  			Action: 'Information',
		  			Id:this.Order.Id
		  		}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){
		  				this.$message(res.Msg)
		  				return
		  			}
		  			
					this.Order = res.Data
		  			
		  		})
		  		.catch(function (response) {
		  			this.$message('网络请求错误')
		  		})
		  },
	  }
	}
</script>

<style scoped>
	.OrderDetail{
		margin: 20px;
		padding: 20px;
	}
	
	.OrderDetail .Top{
		display: flex;
		align-items: center;
		border-bottom: 1px solid rgba(0,0,0,0.1);
		padding-bottom: 20px;
	}
	.OrderDetail .Top .Left{
		
	}
	.OrderDetail .Top .Right{
		flex: 1;
		text-align: right;
		color: #999999;
	}
	
	.Status{
		width: 100%;
		height: 100px;
		background-color: #ffffff;
		border-bottom: 1px solid rgba(0,0,0,0.06);
		display: flex;
		justify-content: center;
		margin-top: 20px;
		padding-top: 40px;
	}
	.Step{
		width: 80%;
	}
	
	.Detail{
		background-color: #FFFFFF;
		margin-top: 20px;
		padding: 30px 20px;
		
	}
	.Detail .List{
		display: flex;
		margin-bottom: 10px;
		padding-bottom: 10px;
		border-bottom: 1px solid rgba(0,0,0,0.05);
		align-items: center;
	}
	.Detail .Title{
		color: rgba(0,0,0,0.3);
	}
	.List .C0,.List .C1,.List .C2,.List .C3,.List .C4,.List .C5,.List .C6{
		width: 120px;
	}
	.List .C0 img{
		height: 80px;
	}
	.List .C1{
		flex: 1;
	}
	.List .C2{
		width: 140px;
		margin-left: 10px;
		margin-right: 10px;
	}
	
	.Count{
		text-align: right;
	}
	.Count span{
		font-size: 0.9rem;
		color: rgba(0,0,0,0.4);
	}
	
	.Express{
		background-color: #FFFFFF;
		margin-top: 20px;
		padding: 30px 20px;
		display: flex;
	}
	.Express .Address{
		width: 400px;
		border-right: 1px solid rgba(0,0,0,0.1);
		padding-right: 20px;
		margin-right: 20px;
	}
	.Express .Address h3{
		color: #999999;
	}
	.Express .Address li{
		margin-bottom: 5px;
		display: flex;
	}
	.Express .Address li span{
		display: inline-block;
		width: 100px;
	}
	
	.Express .Invoice{
		margin-top: 30px;
	}
	
	.Express .Dynamic{
		flex: 1;
	}
	.Express .Dynamic .Info{
		max-height: 200px;
		height: 200px;
		background-color: rgba(0,0,0,0.05);
		padding: 20px;
		flex: 1;
	}
	.Express h3 span{
		float: right;
		font-weight: normal;
		font-size: 14px;
	}
	
	.BaseInfo{
		background-color: #FFFFFF;
		padding: 10px;
		color: rgba(0,0,0,0.4);
	}
</style>
